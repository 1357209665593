import React from 'react';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import HeroSingle from '../components/pages/HeroSingle';
import BirthdayPartiesSlider from '../components/pages/BirthdayPartiesSlider';
import whyhostimg from '../images/whyhostimg.jpg';
import expectimg2 from '../images/expectimg2.jpg';
import LineFull from '../images/linefull.jpg';
import PriceLine from '../images/pricline.png';
import HireDJ from '../images/hiredjimg.jpg';
import HirePhoto from '../images/hirephotoimg.jpg';
import SetupImg from '../images/setupimg.jpg';
import WifiStar from '../images/wifistar.png';
import GreenLogo from '../images/greenlogo.jpg';
import SEO from '../components/seo';
import '../styles/app.scss';

const BirthDay = ({ data }) => {
    const { wordpressPage: post } = data;
    const test_title = data.wordpressPage.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");

    return (
        <>
            <SEO
                title={new_seo_title}
                meta={data.wordpressPage.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[1].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />

            <section className="page-section smallestwdt" id="bookparty">
                <div className="container">
                    <h2 className="bluetxt">BOOK YOUR BIRTHDAY PARTY!</h2>

                    <div className="bookawrap">
                        <a
                            // href={`tel:${post.acf.party_call_us_number}`}
                            href="#packages-options"
                            className="wrtsbtn yellowbtn fullbtn"
                        >
                            {/* CALL US TODAY: {post.acf.party_call_us_number} */}
                            SEE OUR PACKAGES
                        </a>
                    </div>
                    <img src={LineFull} alt="linefull" />
                </div>
            </section>

            <section
                className="page-section respitesec notoppaddsec"
                id="toplftcarous"
            >
                <div className="container">
                    <div className="container flexwrap">
                        <div className="openplft">
                            <div
                                id="myCarousel3"
                                className="carousel slide"
                                data-ride="carousel"
                            >
                                <BirthdayPartiesSlider
                                    galleryImages={post.acf.bp_gallery}
                                />
                            </div>
                        </div>
                        {/* <div className="whylistlft flexbox todwn">
                        <img
                            className="whiteborderimg"
                            src={
                                post.acf.bp_why_image.localFile.childImageSharp
                                    .fluid.src
                            }
                            alt="why hosting"
                        />
                    </div> */}
                        <div className="openprgt">
                            <h2
                                className="bluetxt"
                                dangerouslySetInnerHTML={{
                                    __html: post.acf.bp_title,
                                }}
                            />
                            <div
                                className=""
                                dangerouslySetInnerHTML={{
                                    __html: post.acf.bp_content,
                                }}
                            />
                        </div>
                        {/* <div className="whylistrgt flexbox toup">
                            <h2
                                className="bluetxt"
                                dangerouslySetInnerHTML={{
                                    __html: post.acf.bp_why_title,
                                }}
                            />
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: post.acf.bp_why_reasons,
                                }}
                            />
                        </div> */}
                    </div>
                    {/* <div className="openplft">
                         <div
                            id="myCarousel3"
                            className="carousel slide display_none"
                            data-ride="carousel"
                        >
                            <BirthdayPartiesSlider
                                galleryImages={post.acf.bp_gallery}
                            />
                        </div>
						
                        <div
                            className="display_none"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_book_online_code_widget,
                            }}
                        /> 
                    </div>*/}

                    {/* <div className="openprgt">
                        <h2
                            className="bluetxt display_none"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_title,
                            }}
                        />
                        <div
                        className="display_none"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_content,
                            }}
                        /> 
                    </div>*/}
                </div>
            </section>
            <section
                className="whywelist page-section bg-primary text-white"
                id="openpbasic"
            >
                {/* <div className="container middlewdt flexwrap">
                    <div className="flexbox toup">
                        <h2
                            className="yellowtxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_what_to_expect_title,
                            }}
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_what_to_expect_content,
                            }}
                        />
                    </div>

                    <div className="flexbox todwn">
                        <img
                            className="whiteborderimg"
                            src={
                                post.acf.bp_what_to_expect_image.localFile
                                    .childImageSharp.fluid.src
                            }
                            alt="whitebordering"
                        />
                    </div>
                </div> */}
                <div className="container flexwrap">
                    <div className="whylistlft flexbox todwn">
                        <img
                            className="whiteborderimg"
                            src={
                                post.acf.bp_why_image.localFile.childImageSharp
                                    .fluid.src
                            }
                            alt="why hosting"
                        />
                    </div>

                    <div className="whylistrgt flexbox toup">
                        <h2
                            className="yellowtxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_why_title,
                            }}
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_why_reasons,
                            }}
                        />
                    </div>
                </div>
            </section>
            <section
                className="page-section bg-secondary text-white openphours"
                id="pricingsec"
            >
                <div className="container middlewdt flexwrap">
                    <div className="flexbox toup">
                        <h2
                            className="yellowtxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_what_to_expect_title,
                            }}
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_what_to_expect_content,
                            }}
                        />
                    </div>

                    <div className="flexbox todwn">
                        <img
                            className="whiteborderimg"
                            src={
                                post.acf.bp_what_to_expect_image.localFile
                                    .childImageSharp.fluid.src
                            }
                            alt="whitebordering"
                        />
                    </div>
                </div>
            </section> 
            <section className="page-section centersec nopaddbottsec" id="packages-options">
                <div className="container smallestwdt">
                    <h2
                        className="bluetxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.bp_pricing_title,
                        }}
                    />
                    <p
                        dangerouslySetInnerHTML={{
                            __html: post.acf.bp_pricing_description,
                        }}
                    />

                    <img className="linemar nomartopimg" src={LineFull} alt="linefull" />

                    <div className="packagesdiv">
                        {post.acf.bp_pricing_list.map((item, i) => (
                            <div
                                className="pricingbox bg-primary text-white"
                                key={i}
                            >
                                <h2>
                                    <span
                                        className="yellowtxt"
                                        dangerouslySetInnerHTML={{
                                            __html: item.bp_price_title,
                                        }}
                                    />
                                    <br />
                                    {item.bp_price_ammount}
                                </h2>
                                <img src={PriceLine} alt="priceline" />
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: item.bp_price_package_content,
                                    }}
                                />
                            </div>
                        ))}
                    </div>

                    <p><strong>Cancellation or Rescheduling:</strong><br />
                    If you need to cancel or reschedule your party, please notify us as soon as possible.<br />
                        Parties canceled or rescheduled more than one week (7 days) before the scheduled event date will not incur any charges<br />
                     For parties canceled or rescheduled between 48 hours and one week before the scheduled event date, you must reschedule for an available date. Alternatively, you have the option to apply the remaining balance towards Open-Play or Rock Shop credits.<br /><br />
                     <strong>No-Show:</strong><br />
                        If you fail to notify us of your cancellation and do not show up for your scheduled party, you will be charged the remaining balance of the party package.<br /><br /></p>
                    <h3 class="bluetxt centerme">Birthday Party Partnerships</h3>
                    <div className='pricingtxt'><p>We have birthday party partnerships with Bellacino's Pizza and Grinders, Sweet Home Cakery, StowNut Donut & Diner, and Let’s Get Poppin by Ashly. Please contact us for more details. </p></div> 
                    {/* <div className='addon-flex'>
                        <div>
                            <div className='pricingtxt'><p><strong>Food/Drink:</strong><br />
                                ● Bellacino's Pizza and Grinders: Exclusive catering provided by Bellacino's Pizza and Grinders. Simply fill out a form to select your food items and pay at booking.<br />
                                We kindly ask that you refrain from bringing outside catering food. However, we understand that every child has their preferences, so feel free to bring snacks or alternative meal options for those picky eaters. Please inquire for additional information.</p></div> 
                            <div className='pricingtxt'><p><strong>Desserts:</strong><br />
                                ● Sweet Home Cakery: Custom bakery needs, including cakes, cupcakes, cake pops, cookies, and more, are provided by Sweet Home Cakery. Please inquire for additional information.<br />
                                ● StowNut Donut & Diner: Customize your donut order with preferred flavors, icing, and sprinkles. Please inquire for additional information.</p></div> 

                        </div>
                        <div>
                            <div className='pricingtxt'><p><strong>Balloons:</strong><br />
                                ● Let’s Get Poppin’ by Ashly: Personalized balloon decor provided by Let's Get Poppin’ by Ashly. Balloons will be arranged in the party room before your party begins. Please inquire for additional information.</p></div> 
                            <div className='pricingtxt'><p><strong>Additional Birthday Party Add-ons:</strong><br />
                                ● Tableware Set: includes tablecloths, plates, napkins, cutlery, and cups (offered in pink, blue, red, green): $2 per person<br />
                                ● Balloon Drop (available for private parties only): $85<br />
                                ● Popcorn Machine: $45<br />
                                ● Rock Shop Party Favor: $10 per kid<br />
                                ● Bubble Machine: $25<br />
                                ● Personalized Birthday Kid Photo Slideshow on TVs (30 photos rotating): $25<br />
                                ● Additional 1 hour (available only for specific birthday party time slots): $150</p></div> 
                        </div>
                    </div> */}

                    <div className="oplayhealwrap">
                        {/* <h5 class="bluetxt">Book online</h5>
                        <div
                            className=""
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_book_online_code_widget,
                            }}
                        /> */}
                    </div>
                    <div className="oplayhealwrap">
                        <h5 className="bluetxt">Book online</h5>
                        <iframe
                            src="https://werockthespectrumstow.wrtsfranchise.com/healcode-bp.php"
                            title="appointment"
                            className="healcode_widgets"
                        />
                    </div> 


                </div>
            </section>
          

            {/* <section
                className="page-section bg-secondary text-white openphours"
                id="pricingsec"
            >
                <div className="container middlewdt flexwrap">
                    <div className="flexbox toup">
                        <h2
                            className="yellowtxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_what_to_expect_title,
                            }}
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_what_to_expect_content,
                            }}
                        />
                    </div>

                    <div className="flexbox todwn">
                        <img
                            className="whiteborderimg"
                            src={
                                post.acf.bp_what_to_expect_image.localFile
                                    .childImageSharp.fluid.src
                            }
                            alt="whitebordering"
                        />
                    </div>
                </div>
            </section> */}

            

            <section className="page-section centersec notoppaddsec display_none">
                <div className="container smallestwdt">
                    <img className="linemar" src={LineFull} alt="linefull" />


                    <div className="greenlft">
                        <img src={GreenLogo} alt="greenlogo" />
                    </div>

                    <div className="greenrgt">
                        <h2 className="bluetxt">We Use Only Green Products</h2>
                        <p className="biggertxt lastitem">
                            We believe in keeping our precious ones healthy and
                            safe. Our gym is cleaned daily at the end of the day
                            with only green products.
                        </p>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default BirthDay;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            content
            acf {
                bp_book_online_code_widget
                bp_content
                bp_gallery {
                    id
                    localFile {
                        id
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
                bp_hire_a_dj_content
                bp_hire_a_dj_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
                bp_hire_a_dj_title
                bp_hire_photographer_content
                bp_hire_photographer_title
                bp_open_play_unlimited_link
                bp_pricing_description
                bp_pricing_title
                bp_private_playdates_title
                bp_private_playdates_content
                bp_set_open_air_content
                bp_title
                bp_set_open_air_title
                bp_what_to_expect_content
                bp_why_title
                bp_why_reasons
                bp_what_to_expect_title
                bp_hire_photographer_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
                bp_invitation_pdf {
                    url {
                        source_url
                    }
                }
                bp_pricing_list {
                    bp_price_ammount
                    bp_price_package_content
                    bp_price_title
                }
                bp_set_open_air_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
                bp_what_to_expect_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
                party_call_us_number
                bp_why_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
            }
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;
